const initialState = {
  objectFileManager: {},
};

const MainReducers = (state = initialState, action) => {
    // console.log(state, action,'eleg')
  switch (action.type) {
    case 'OBJECT-FILE-MANAGER':
      return {
        ...state,
        objectFileManager: action.data,
      };

    default:
      return {
        ...state,
      };
  }
};

export default MainReducers;
