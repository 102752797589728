import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Storage from '../../../repository/storage';
import Tooltip from '@material-ui/core/Tooltip';
import API, { API_SERVER, USER_ME, APPS_SERVER, BBB_URL, BBB_KEY } from '../../../repository/api';
import SocketContext from '../../../socket';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import ContentLoader from 'react-content-loader';
import Select from 'react-select';
import ToggleSwitch from 'react-switch';
import { Card, Modal, Col, Row, Form, Dropdown } from 'react-bootstrap';
import { left } from 'styled-system';
import ModalAddEvent from '../../ModalGlobal/ModalAddEventMeeting';
import { AiOutlineReload } from 'react-icons/ai';
const featureQuota = require('../../../../package.json')['feature-quota'];

const access_company = JSON.parse(localStorage.getItem('user'));

const Msg = ({ id, desc, socket, startTimer }) => {
  const readReminder = (id) => {
    API.put(`${API_SERVER}v1/notification/read`, { id }).then((res) => {
      if (res.data.error)
        // console.log('Gagal read');
        socket.emit('send', { companyId: Storage.get('user').data.company_id });
    });
  };

  const snoozeTime = (data, time) => {
    // console.log(data, time);
    startTimer(time * 60);
  };

  return (
    <div>
      {desc}
      <br />
      <div>
        <label>Snooze&nbsp;</label>
        <button style={{ marginTop: '8px', cursor: 'pointer' }} onClick={(e) => snoozeTime({ id, desc }, 10)}>
          10 Minutes
        </button>
        <button style={{ marginTop: '8px', cursor: 'pointer' }} onClick={(e) => snoozeTime({ id, desc }, 30)}>
          30 Minutes
        </button>

        <button style={{ marginTop: '8px', cursor: 'pointer', float: 'right' }} onClick={(e) => readReminder(id)}>
          Dismiss
        </button>
      </div>
    </div>
  );
};

class SidebarClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webVersion: '',
      showAccessMeeting: access_company.data.access_meeting > 0 ? true : false,
      showAccessWebinar: access_company.data.access_webinar > 0 ? true : false,
      showAccessTraining: access_company.data.access_training > 0 ? true : false,
      showAccessCalendar: access_company.data.access_calendar > 0 ? true : false,
      showAccessReport: access_company.data.access_report > 0 ? true : false,
      showAccessFilemanager: access_company.data.access_filemanager > 0 ? true : false,
      showAccessNews: access_company.data.access_news > 0 ? true : false,
      showAccessProject: access_company.data.access_project > 0 ? true : false,
      menuAktif: '/',
      sideMenu: true,
      sideMenuCollapse: false,
      companyName: '',
      access_training: false,
      project: [],
      companyId: Storage.get('user').data.company_id,
      isLoading: false,
      sidebarOpen: false,
      search: '',
      modalAddProject: false,
      modalForceDelete: false,
      limited: false,
      optionsProjectAdmin: [],
      valueProjectAdmin: [],
      branchCompany: [],
      defaultBranch: [],
      valueUser: [],
      folderName: '',
      deleteProjectName: '',
      deleteProjectId: '',
      modalDelete: false,
      modalEdit: false,
      modalSharing: false,
      defaultProjectAdmin: [],
      defaultUsers: [],
      share: [],
      projectShareId: '',
      email: '',
      alertSharing: '',

      notifUnread: 0,

      time: {},
      seconds: 10,
      showModalMeeting: false,
      gb: [],
      limitCompany: [],
    };

    this.waktu = 10;
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  fetchWebVersion = async () => {
    try {
      const response = await API.get(`${API_SERVER}v1/version/web/1`);
      this.setState({ webVersion: response.data.data[0].value });
    } catch (error) {}
  };

  toogleModalMeeting = () => {
    this.setState({ showModalMeeting: !this.state.showModalMeeting });
  };

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  startTimer(time) {
    this.waktu = time;
    this.timer = 0;
    if (this.timer === 0 && this.waktu > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.waktu - 1;
    this.setState({ time: this.secondsToTime(seconds), seconds: seconds });
    this.waktu = seconds;

    // Check if we're at zero.
    if (seconds === 0) {
      toast(<Msg id={0} desc={`adsfasdf`} socket={this.props.socket} startTimer={this.startTimer} />, {
        autoClose: false,
        type: toast.TYPE.INFO,
      });
      clearInterval(this.timer);
    }
  }

  //   sideMenuCollapse = e =>{
  //     this.setState({sideMenu:!this.state.sideMenu});
  //     // console.log('side menu',this.state.sideMenu);
  // }

  async fetchProject() {
    try {
      this.setState({ isLoading: true });
      
      const user = Storage.get('user').data;
      const { level, user_id: userId } = user;
      const { companyId } = this.state;
  
      const res = await API.get(`${API_SERVER}v1/sidebar/project-list/${level}/${userId}/${companyId}`);
      
      if (res.data.result && res.data.result.length > 0) {
        try {
          const projectId = res.data.result[0].project_id;
          const resDetail = await API.get(`${API_SERVER}v1/sidebar/project-detail/${level}/${userId}/${projectId}/${companyId}`);
          
          // console.log(resDetail)

          res.data.result[0].event = resDetail.data.result[0].event;
          res.data.result[0].open = true;
  
          this.setState({ project: res.data.result, isLoading: false });
          this.forceUpdate();
        } catch (error) {
          this.setState({ isLoading: false });
          toast.error(`Error fetching event data: ${error}`);
          throw error;
        }
      } else {
        this.setState({ project: res.data.result, isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      toast.error('Error fetching project data');
    }
  }  

  clickDelete = (e, id, name) => {
    this.setState({
      deleteProjectId: id,
      deleteProjectName: name,
      modalDelete: true,
      modalEdit: false,
    });
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
  };
  clickEdit = (e, id) => {
    API.get(`${API_SERVER}v1/project-read/${id}`).then((res) => {
      if (res.status === 200) {
        this.setState({
          deleteProjectId: id,
          deleteProjectName: res.data.result.name,

          editProjectId: id,
          editProjectName: res.data.result.name,
          valueProjectAdmin: res.data.result.project_admin ? res.data.result.project_admin.split(',').map(Number) : [],
          valueUser: res.data.result.user ? res.data.result.user.split(',').map(Number) : [],
          limited: res.data.result.is_limit === 0 ? false : true,
        });
        let defValPA = res.data.result.project_admin ? res.data.result.project_admin.split(',').map(Number) : [];
        let defValU = res.data.result.user ? res.data.result.user.split(',').map(Number) : [];
        defValPA.forEach((item) => {
          if (this.state.optionsProjectAdmin.filter((x) => x.value === item).length) {
            this.state.defaultProjectAdmin.push({
              value: item,
              label: this.state.optionsProjectAdmin.filter((x) => x.value === item)[0].label,
            });
          }
        });
        defValU.forEach((item) => {
          if (this.state.optionsProjectAdmin.filter((x) => x.value === item).length) {
            this.state.defaultUsers.push({
              value: item,
              label: this.state.optionsProjectAdmin.filter((x) => x.value === item)[0].label,
            });
          }
        });
        this.setState({
          modalEdit: true,
        });
      }
    });
    this.fetchShare(id);
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
  };
  clickAddEvent = (e, id) => {
    toast.warning('Button add event clicked');
  };
  clickAddProject = (e, id) => {
    this.setState({ modalAddProject: true });
  };
  closeModalProject = (e) => {
    this.setState({ modalAddProject: false, valueProjectAdmin: [], limited: false, valueUser: [] });
  };
  closeModalDelete = (e) => {
    this.setState({ modalDelete: false, deleteProjectName: '', deleteProjectId: '' });
  };
  closeModalForceDelete = (e) => {
    this.setState({ modalForceDelete: false, deleteProjectName: '', deleteProjectId: '' });
  };
  closeModalEdit = (e) => {
    this.setState({
      modalEdit: false,
      alert: '',
      folderName: '',
      valueProjectAdmin: [],
      limited: false,
      valueUser: [],
      defaultProjectAdmin: [],
      defaultUsers: [],
      modalSharing: false,
      projectShareId: '',
      alertSharing: '',
    });
  };
  toggleSwitch(checked) {
    this.setState({ limited: !this.state.limited });
  }
  saveProject = (e) => {
    e.preventDefault();
    const formData = {
      name: this.state.folderName,
      company: this.state.companyId,
      mother: 0,
      project_admin: this.state.valueProjectAdmin,
      is_limit: this.state.limited ? 1 : 0,
      user: this.state.valueUser,
      aSekretaris: 1,
      aModerator: 1,
      aPembicara: 1,
      aOwner: 1,
      aPeserta: 1,
      created_by: Storage.get('user').data.user_id,
    };

    API.post(`${API_SERVER}v1/folder`, formData).then((res) => {
      if (res.status === 200) {
        if (res.data.error) {
          toast.warning(res.data.result);
          this.setState({ alert: res.data.result });
        } else {
          toast.success(`New project ${this.state.folderName} created`);
          this.setState(
            {
              modalAddProject: false,
              folderName: '',
              valueProjectAdmin: [],
              valueUser: [],
              limited: false,
              defaultProjectAdmin: [],
              defaultUsers: [],
            },
            () => {
              this.fetchProject();
              this.props.history.push(`/detail-project/${res.data.result}/default`);
            },
          );
        }
      }
    });
  };
  onChangeInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'attachmentId') {
      this.setState({ [name]: e.target.files });
    } else {
      this.setState({ [name]: value });
    }
  };
  deleteProject(checkFiles) {
    API.delete(`${API_SERVER}v1/project/${this.state.deleteProjectId}`, { checkFiles: checkFiles }).then((res) => {
      if (res.status === 200) {
        if (res.data.error) {
          if (res.data.result === 'The directory you are going to delete has several files') {
            this.setState({ modalDelete: false, modalForceDelete: true });
          } else {
            toast.error(`Failed to delete project ${this.state.deleteProjectName}`);
          }
        } else {
          toast.success(`${this.state.deleteProjectName} deleted`);
          this.setState({ deleteProjectId: '', deleteProjectName: '', modalDelete: false, modalForceDelete: false });
          this.fetchProject();
        }
      }
    });
  }
  editProject() {
    let form = {
      name: this.state.editProjectName,
      project_admin: this.state.valueProjectAdmin,
      is_limit: this.state.limited ? 1 : 0,
      user: this.state.valueUser,
      aSekretaris: 1,
      aModerator: 1,
      aPembicara: 1,
      aOwner: 1,
      aPeserta: 1,
    };
    API.put(`${API_SERVER}v1/project/${this.state.editProjectId}`, form).then((res) => {
      if (res.status === 200) {
        if (res.data.error) {
          toast.error(`Failed to update ${this.state.editProjectName}`);
        } else {
          toast.success(`${this.state.editProjectName} updated`);
          this.setState({
            editProjectId: '',
            editProjectName: '',
            modalEdit: false,
            valueProjectAdmin: [],
            valueUser: [],
            limited: false,
            defaultProjectAdmin: [],
            defaultUsers: [],
            alertSharing: '',
          });
          this.fetchProject();

          if (window.location.pathname.includes('detail-project')) {
            window.location.reload();
          }
        }
      }
    });
  }
  fetchShare(id) {
    API.get(`${API_SERVER}v2/project/share/${id}`).then((res) => {
      if (res.status === 200) {
        if (res.data.error) {
          toast.error(`Gagal fetch data share`);
        } else {
          this.setState({
            share: res.data.result,
            projectShareId: id,
          });
        }
      }
    });
  }
  share() {
    let form = {
      project_id: this.state.projectShareId,
      email: this.state.email,
    };
    API.post(`${API_SERVER}v2/project/share`, form).then((res) => {
      if (res.status === 200) {
        if (res.data.error) {
          toast.error(`Failed to share project ${this.state.editProjectName}`);
          this.setState({ alertSharing: '' });
        } else {
          if (res.data.result === 'success') {
            this.setState({ email: '', alertSharing: '' });
            this.fetchShare(this.state.projectShareId);
            // toast.success(`Sharing project to ${form.email}`)
          } else {
            this.setState({ alertSharing: `Email ${form.email} not registered in ICADEMY` });
          }
        }
      }
    });
  }
  deleteShare(id) {
    API.delete(`${API_SERVER}v2/project/share/${id}`).then((res) => {
      if (res.status === 200) {
        if (res.data.error) {
          toast.error(`Failed to delete sharing data`);
        } else {
          this.fetchShare(this.state.projectShareId);
        }
      }
    });
  }

  fetchCheckAccess(company_id, code) {
    API.get(`${API_SERVER}v2/global-settings/check-access`, { role: 'admin', company_id, code }).then((res) => {
      this.setState({ gb: res.data.result });
    });
  }
  checkLimitCompany() {
    API.get(`${API_SERVER}v2/company-limit/${this.state.companyId}`).then((res) => {
      if (res.status === 200) {
        if (!res.data.error) {
          this.setState({ limitCompany: res.data.result });
        } else {
          toast.error('Error, failed check limit company');
        }
      }
    });
  }

  getDetailCompany(idCompany) {
    const linkURL = `${API_SERVER}v1/company/${idCompany}`;
    API.get(linkURL).then((res) => {
      if (res.data.error) {
        toast.warning('Error Get Detail Company');
      } else {
        this.setState({
          access_training: res.data.result.access_training === 1 ? true : false,
        });
      }
    });
  }

  getBranchCompany(idCompany) {
    const linkURLCabang = `${API_SERVER}v1/branch/company/${idCompany}`;
    API.get(linkURLCabang)
      .then((res) => {
        if (res.data.error) {
          toast.warning('Error Get Branch Company.');
        } else {
          let listBranchCompany = res.data.result[0];
          listBranchCompany = listBranchCompany.map((data, index) => {
            return {
              value: data.branch_id,
              label: data.branch_name,
            };
          });
          this.setState({ branchCompany: listBranchCompany });
        }
      })
      .catch((err) => {
        toast.warning('Something Error..', err);
      });
  }

  componentDidMount() {
    this.fetchWebVersion();
    // try {
    //   API.get(`${USER_ME}${Storage.get('user').data.email}`).then(res => { })
    // } catch (e) { console.log(e, 1) }
    API.get(`${USER_ME}${Storage.get('user').data.email}`).then((res) => {
      const idCompany = localStorage.getItem('companyID')
        ? localStorage.getItem('companyID')
        : res.data.result.company_id;
      this.setState(
        {
          companyName: res.data.result.company_name,
          companyId: idCompany,
        },
        () => {
          this.getDetailCompany(idCompany);
          this.getBranchCompany(idCompany);
        },
      );
      this.fetchProject();
      if (this.state.optionsProjectAdmin.length === 0 || this.state.optionsProjectAdmin.length === 0) {
        API.get(`${API_SERVER}v1/user/company/${this.state.companyId}`)
          .then((response) => {
            response.data.result.map((item) => {
              this.state.optionsProjectAdmin.push({ value: item.user_id, label: item.name });
            });

            this.setState({ allUser: response.data.result });
          })
          .catch(function (error) {
            // console.log(error);
          });
      }
    });
    this.setState({ menuAktif: window.location.pathname });

    this.fetchNotif();
    this.fetchReminder();

    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });

    this.props.socket.on('broadcast', (data) => {
      // console.log('broadcast sidebar ', data);
      if (data.companyId === Storage.get('user').data.company_id) {
        this.fetchNotif();
      }
    });
    this.fetchCheckAccess(Storage.get('user').data.company_id, [
      'CD_PROJECT',
      'CD_MEETING',
      'R_MEETINGS',
      'R_MEETING',
      'R_ATTENDANCE',
      'CD_WEBINAR',
    ]);
    this.checkLimitCompany();
  }

  fetchReminder() {
    API.get(`${API_SERVER}v1/notification/all/${Storage.get('user').data.user_id}`).then((res) => {
      // const Notif = res.data.result[0].filter(item => item.isread === 0 && item.tag === 1);

      let now = moment(new Date()).format('YYYY-MM-DD');
      const Remind = res.data.result[0]
        .filter((item) => item.isread === 0 && item.tag === 2)
        .filter((item) => item.created_at.substring(0, 10) === now);

      Remind.map((item, i) => {
        let dateNow = moment().format('LLL');
        let fromNow = moment(item.created_at).format('LLL');
        if (dateNow === fromNow) {
          toast(<Msg id={item.id} desc={item.description} socket={this.props.socket} startTimer={this.startTimer} />, {
            autoClose: false,
            type: toast.TYPE.INFO,
          });
        }
      });
    });
  }

  fetchNotif() {
    API.get(`${API_SERVER}v1/notification/unread/${Storage.get('user').data.user_id}`).then((res) => {
      if (res.data.error) {
        toast.warning(res.data.result);
      } else {
        this.setState({ notifUnread: res.data.result[0].length });
      }
    });
  }

  getProject(id) {
    let index = this.state.project.findIndex((x) => x.project_id === id);
    this.state.project[index].open = !this.state.project[index].open;
    this.state.project[index].more = false;
    this.state.project[index].loading = false;
    API.get(
      `${API_SERVER}v1/sidebar/project-detail/${Storage.get('user').data.level}/${
        Storage.get('user').data.user_id
      }/${id}/${this.state.companyId}`,
    ).then((res2) => {
      if (res2.data.error) {
        this.setState({ isLoading: false });
        toast.error('Error get data event');
      } else {
        this.state.project[index].event = res2.data.result[0].event;
        this.state.project[index].loading = false;
        this.forceUpdate();
      }
    });
  }

  openProject(id) {
    let index = this.state.project.findIndex((x) => x.project_id === id);
    if (this.state.project[index].open === true) {
      let index = this.state.project.findIndex((x) => x.project_id === id);
      this.state.project[index].open = !this.state.project[index].open;
      this.state.project[index].more = false;
      this.state.project[index].loading = false;
      this.forceUpdate();
    } else if (!this.state.project[index].open) {
      this.state.project[index].open = !this.state.project[index].open;
      this.state.project[index].loading = true;
      this.forceUpdate();
      API.get(
        `${API_SERVER}v1/sidebar/project-detail/${Storage.get('user').data.level}/${
          Storage.get('user').data.user_id
        }/${id}/${this.state.companyId}`,
      ).then((res2) => {
        if (res2.data.error) {
          this.setState({ isLoading: false });
          toast.error('Error get data event');
        } else {
          this.state.project[index].event = res2.data.result[0].event;
          this.state.project[index].loading = false;
          this.forceUpdate();
        }
      });
    }
    // console.log('ALVINSSS2', this.state.project);
  }

  openLink(dataEvent, dataProject) {
    if (dataEvent.type === 'Webinar') {
      if (dataEvent.status === 'Finish' || dataEvent.status === 'Done') {
        return `/webinar/riwayat/${dataEvent.id}`;
      } else if (dataEvent.status === 'On Going') {
        return `/detail-project/${dataProject.project_id}/0`;
      } else {
        if (dataEvent.training_course_id !== 0) {
          return `/webinar/add/${dataProject.project_id}/${dataEvent.id}/default`;
        } else {
          return `/webinar/add/${dataProject.project_id}/${dataEvent.id}/by-training`;
        }
      }
    } else {
      return `/detail-project/${dataProject.project_id}/${dataEvent.id}`;
    }
  }

  render() {
    let access = Storage.get('access');
    let levelUser = Storage.get('user').data.level;
    let accessProjectManager = levelUser === 'client' ? false : true;
    let groupUser = Storage.get('user').data.grup_name;
    let companyType = Storage.get('user').data.company_type;
    let accessAddProject = levelUser === 'client' ? false : true;
    let avatar = ['assets/new_ui/avatar1.png', 'assets/new_ui/avatar2.png', 'assets/new_ui/avatar3.png'];
    const { closeMenuToggle } = this.props;

    // ** GLOBAL SETTINGS ** //
    let cdProject = '';
    if (levelUser === 'admin' || levelUser === 'superadmin') {
      cdProject = this.state.gb.length && this.state.gb.filter((item) => item.code === 'CD_PROJECT')[0].status;
    }
    // CREATE & DELETE WEBINAR
    let cdWebinar = this.state.gb.length && this.state.gb.filter((item) => item.code === 'CD_WEBINAR')[0].status;

    // CREATE & DELETE MEETING
    let cdMeeting = this.state.gb.length && this.state.gb.filter((item) => item.code === 'CD_MEETING')[0].status; // // console.log('Storage: ', Storage.get('user'));

    let menuClients = {
      murid: {
        submenu: [
          { iconOn: 'info-on.svg', iconOff: 'ujianoff.svg', label: 'My Class', link: '/murid/kelas', show: true },
          {
            iconOn: 'matapelajaranon.svg',
            iconOff: 'graduate.svg',
            label: 'Subjects',
            link: '/murid/mata-pelajaran',
            show: true,
          },
          { iconOn: 'tugason.svg', iconOff: 'tugasoff.svg', label: 'Task', link: '/murid/tugas', show: true },
          { iconOn: 'tugason.svg', iconOff: 'tugasoff.svg', label: 'Quiz', link: '/murid/kuis', show: true },
          { iconOn: 'info-on.svg', iconOff: 'ujianoff.svg', label: 'Exam', link: '/murid/ujian', show: true },
          {
            iconOn: 'laporanraporon.svg',
            iconOff: 'laporanraporoff.svg',
            label: 'Report/Raport',
            link: '/rapor',
            show: true,
          },
          { iconOn: 'arrow.svg', iconOff: 'arrow.svg', label: 'Logout', link: '/logout', show: true },
        ],
      },

      guru: {
        submenu: [
          { iconOn: 'tugason.svg', iconOff: 'tugasoff.svg', label: 'Meeting Sekolah', link: '/meeting', show: true },
          {
            iconOn: 'info-on.svg',
            iconOff: 'info.svg',
            label: 'Jadwal Mengajar',
            link: '/jadwal-mengajar',
            show: true,
          },
          {
            iconOn: 'tugason.svg',
            iconOff: 'tugasoff.svg',
            label: 'Laporan Murid',
            link: '/guru-laporan/ratakelas',
            show: true,
          },
          {
            iconOn: 'matapelajaranon.svg',
            iconOff: 'graduate.svg',
            label: 'Courses',
            link: '/guru-info/kursus',
            show: true,
          },
          {
            iconOn: 'tugason.svg',
            iconOff: 'tugasoff.svg',
            label: 'Exercise & Exam',
            link: '/guru-info/ujian',
            show: true,
          },
          {
            iconOn: 'info-on.svg',
            iconOff: 'info.svg',
            label: 'Class Information',
            link: '/guru-info/informasi-kelas',
            show: true,
          },
          {
            iconOn: 'instructor-on.svg',
            iconOff: 'instructor.svg',
            label: 'KPI Teacher',
            link: '/guru-info/kpi',
            show: true,
          },
          { iconOn: 'arrow.svg', iconOff: 'arrow.svg', label: 'Logout', link: '/logout', show: true },
        ],
      },

      parents: {
        submenu: [
          {
            iconOn: 'matapelajaranon.svg',
            iconOff: 'graduate.svg',
            label: 'Student Learning',
            link: '/parent-learning',
            show: true,
          },
          {
            iconOn: 'matapelajaranon.svg',
            iconOff: 'graduate.svg',
            label: 'Curriculum',
            link: '/parent-curriculum',
            show: true,
          },
          { iconOn: 'tugason.svg', iconOff: 'tugasoff.svg', label: 'Syllabus', link: '/parent-syllabus', show: true },
          {
            iconOn: 'laporanraporon.svg',
            iconOff: 'laporanraporoff.svg',
            label: 'Report/Raport',
            link: '/parent-rapor',
            show: true,
          },
          { iconOn: 'arrow.svg', iconOff: 'arrow.svg', label: 'Logout', link: '/logout', show: true },
        ],
      },

      principal: {
        submenu: [
          { iconOn: 'kursus.svg', iconOff: 'kursus.svg', label: 'Dashboard', link: '/', show: true },
          { iconOn: 'tugason.svg', iconOff: 'tugasoff.svg', label: 'Meeting Sekolah', link: '/meeting', show: true },
          {
            iconOn: 'laporanraporon.svg',
            iconOff: 'laporanraporoff.svg',
            label: 'Kurikulum',
            link: '/principal-pelajaran',
            show: true,
          },
          {
            iconOn: 'laporanraporon.svg',
            iconOff: 'laporanraporoff.svg',
            label: 'Laporan Kelas',
            link: '/principal-rapor',
            show: true,
          },
          {
            iconOn: 'laporanraporon.svg',
            iconOff: 'laporanraporoff.svg',
            label: 'Laporan Murid',
            link: '/principal-rapor-murid',
            show: true,
          },
          {
            iconOn: 'laporanraporon.svg',
            iconOff: 'laporanraporoff.svg',
            label: 'Kinerja Guru',
            link: '/principal-kinerja',
            show: true,
          },
          // { iconOn: 'laporanraporon.svg', iconOff: 'laporanraporoff.svg', label: 'Evaluasi', link: '/principal-evaluasi' },
          { iconOn: 'arrow.svg', iconOff: 'arrow.svg', label: 'Logout', link: '/logout', show: true },
        ],
      },

      management: {
        submenu: [
          { iconOn: 'kursus.svg', iconOff: 'kursus.svg', label: 'Dashboard', link: '/', show: true },
          { iconOn: 'tugason.svg', iconOff: 'tugasoff.svg', label: 'Meeting Sekolah', link: '/meeting', show: true },
          {
            iconOn: 'laporanraporon.svg',
            iconOff: 'laporanraporoff.svg',
            label: 'Kurikulum',
            link: '/management-kurikulum',
            show: true,
          },
          {
            iconOn: 'laporanraporon.svg',
            iconOff: 'laporanraporoff.svg',
            label: 'Laporan Kelas',
            link: '/management-rapor',
            show: true,
          },
          {
            iconOn: 'laporanraporon.svg',
            iconOff: 'laporanraporoff.svg',
            label: 'Laporan Murid',
            link: '/management-rapor-murid',
            show: true,
          },
          {
            iconOn: 'laporanraporon.svg',
            iconOff: 'laporanraporoff.svg',
            label: 'Kinerja Guru',
            link: '/management-kinerja',
            show: true,
          },
          // { iconOn: 'laporanraporon.svg', iconOff: 'laporanraporoff.svg', label: 'Evaluasi ', link: '/management-evaluasi' },
          { iconOn: 'arrow.svg', iconOff: 'arrow.svg', label: 'Logout', link: '/logout', show: true },
        ],
      },

      other: {
        submenu: [
          // { iconOn: 'open-folder.svg', iconOff: 'open-folder.svg', label: 'Files', link: '/files' },
          { iconOn: 'news-sidebar.svg', iconOff: 'news-sidebar.svg', label: 'News', link: '/news', show: true },
          { iconOn: 'open-folder.svg', iconOff: 'open-folder.svg', label: 'Project', link: '/project', show: true },
          {
            iconOn: 'materi.svg',
            iconOff: 'materi.svg',
            label: "User's Task Report",
            link: '/gantt/report',
            show: true,
          },
          // { iconOn: 'materi.svg', iconOff: 'materi.svg', label: 'Kursus & Materi', link: '/kursus', access: 'course' },
          // { iconOn: 'forum.svg', iconOff: 'forum.svg', label: 'Forum', link: '/forum', access: 'forum' },
          // { iconOn: 'conference.svg', iconOff: 'conference.svg', label: 'Group Meeting', link: '/meeting', access: access.manage_group_meeting ? 'manage_group_meeting' : 'group_meeting' },
          // { iconOn: 'kursus.svg', iconOff: 'kursus.svg', label: 'Manage Courses', link: '/kursus-materi', access: 'manage_course' },
          // { iconOn: 'sertifikat.svg', iconOff: 'sertifikat.svg', label: 'Certificate', link: '/certificate' },
          { iconOn: 'logout-sidebar.svg', iconOff: 'logout-sidebar.svg', label: 'Logout', link: '/logout', show: true },
        ],
      },

      menuAtas: [
        {
          iconOn: 'start-up.svg',
          iconOff: 'start-up.svg',
          label: 'Project',
          link: '/project',
          show: this.state.showAccessProject,
        },
        { iconOn: 'party.svg', iconOff: 'party.svg', label: 'Event', link: '#' },
        {
          iconOn: 'bell.svg',
          iconOff: 'bell.svg',
          label: 'Notification',
          link: '/notification',
          isBadge: true,
          show: true,
        },
        {
          iconOn: 'calendar-2.svg',
          iconOff: 'calendar-2.svg',
          label: 'Calendar',
          link: '/calendar',
          show: this.state.showAccessCalendar,
        },
        {
          iconOn: 'open-folder.svg',
          iconOff: 'open-folder.svg',
          label: 'Files',
          link: '/files',
          show: this.state.showAccessFilemanager,
        },
        {
          iconOn: 'business-report.svg',
          iconOff: 'business-report.svg',
          label: 'Timeline',
          link: '/gantt/report',
          show: this.state.showAccessReport,
        },
        // { iconOn: 'office-building.svg', iconOff: 'office-building.svg', label: 'Company', link: '/my-company' },
        {
          iconOn: 'envelope.svg',
          iconOff: 'envelope.svg',
          label: 'News',
          link: '/news',
          show: this.state.showAccessNews,
        },
        // { iconOn: 'mail-2.svg', iconOff: 'mail-2.svg', label: 'Announcement', link: '/pengumuman' },
      ],
      menuBawah: [
        // { iconOn: 'ptcon.svg', iconOff: 'ptcoff.svg', label: 'PTC', link: '/ptc' },
        { iconOn: 'settings.svg', iconOff: 'settings.svg', label: 'Setting', link: '/pengaturan', show: true },
        {
          iconOn: 'customer-service.svg',
          iconOff: 'customer-service.svg',
          label: 'Support',
          link: '/bantuan',
          show: true,
        },
        { iconOn: 'arrow.svg', iconOff: 'arrow.svg', label: 'Logout', link: '/logout', show: true },
      ],

      submenuTraining: [{ iconOn: 'arrow.svg', iconOff: 'arrow.svg', label: 'Logout', link: '/logout', show: true }],
      menuAtasTraining: [
        {
          iconOn: 'bell.svg',
          iconOff: 'bell.svg',
          label: 'Notification',
          link: '/notification',
          isBadge: true,
          show: true,
        },
        { iconOn: 'party.svg', iconOff: 'party.svg', label: 'Training', link: '/training/plan-user', show: true },
        { iconOn: 'calendar-2.svg', iconOff: 'calendar-2.svg', label: 'Calendar', link: '/calendar', show: true },
        // { iconOn: 'open-folder.svg', iconOff: 'open-folder.svg', label: 'Files', link: '/files', show: true },
        { iconOn: 'envelope.svg', iconOff: 'envelope.svg', label: 'News', link: '/news', show: true },
      ],
      menuBawahTraining: [
        { iconOn: 'settings.svg', iconOff: 'settings.svg', label: 'Settings', link: '/pengaturan', show: true },
        { iconOn: 'bantuan-on.svg', iconOff: 'customer-service.svg', label: 'Support', link: '/bantuan', show: true },
        { iconOn: 'arrow.svg', iconOff: 'arrow.svg', label: 'Logout', link: '/logout', show: true },
      ],
    };

    let menuAdmins = {
      submenu: [
        // { iconOn: 'open-folder.svg', iconOff: 'open-folder.svg', label: 'Files', link: '/files' },
        { iconOn: 'news-sidebar.svg', iconOff: 'news-sidebar.svg', label: 'News', link: '/news', show: true },
        { iconOn: 'open-folder.svg', iconOff: 'open-folder.svg', label: 'Project', link: '/project', show: true },
        { iconOn: 'materi.svg', iconOff: 'materi.svg', label: "User's Task Report", link: '/gantt/report', show: true },
        // { iconOn: 'materi.svg', iconOff: 'materi.svg', label: 'Kursus & Materi', link: '/kursus' },
        // { iconOn: 'forum.svg', iconOff: 'forum.svg', label: 'Forum', link: '/forum' },
        // { iconOn: 'conference.svg', iconOff: 'conference.svg', label: 'Group Meeting', link: '/meeting' },
        // { iconOn: 'kursus.svg', iconOff: 'kursus.svg', label: 'Manage Courses', link: '/kursus-materi' },
        // { iconOn: 'sertifikat.svg', iconOff: 'sertifikat.svg', label: 'Certificate', link: '/certificate' },
        // { iconOn: 'sertifikat.svg', iconOff: 'sertifikat.svg', label: 'Manage Certificates', link: '/certificate-admin' },
        {
          iconOn: 'company-sidebar.svg',
          iconOff: 'company-sidebar.svg',
          label: 'My Company',
          link: '/my-company',
          show: true,
        },
        { iconOn: 'user-sidebar.svg', iconOff: 'user-sidebar.svg', label: 'Users', link: '/user-company', show: true },
        // { iconOn: 'kursus.svg', iconOff: 'kursus.svg', label: 'Global Settings', link: '/global-settings',show: true },
        { iconOn: 'logout-sidebar.svg', iconOff: 'logout-sidebar.svg', label: 'Logout', link: '/logout', show: true },
      ],
      submenuPendidikan: [
        { iconOn: 'conference.svg', iconOff: 'conference.svg', label: 'My Company', link: '/my-company', show: true },
        { iconOn: 'conference.svg', iconOff: 'conference.svg', label: 'Users', link: '/user-company', show: true },
        { iconOn: 'kursus.svg', iconOff: 'kursus.svg', label: 'Classes', link: '/learning/registrasi', show: true },
        {
          iconOn: 'materi.svg',
          iconOff: 'materi.svg',
          label: 'Lessons',
          link: '/learning/daftar-pelajaran',
          show: true,
        },
        { iconOn: 'forum.svg', iconOff: 'forum.svg', label: 'Room', link: '/learning/ruangan-mengajar', show: true },
        {
          iconOn: 'conference.svg',
          iconOff: 'conference.svg',
          label: 'Schedule',
          link: '/learning/jadwal-mengajar',
          show: true,
        },
        { iconOn: 'kursus.svg', iconOff: 'kursus.svg', label: 'Personnel', link: '/learning/personalia', show: true },
        { iconOn: 'sertifikat.svg', iconOff: 'sertifikat.svg', label: 'Preference', link: '/learning/kpi', show: true },
        { iconOn: 'sertifikat.svg', iconOff: 'sertifikat.svg', label: 'Report', link: '/learning/laporan', show: true },
        // { iconOn: 'kursus.svg', iconOff: 'kursus.svg', label: 'Global Settings', link: '/global-settings' ,show: true},
        { iconOn: 'arrow.svg', iconOff: 'arrow.svg', label: 'Logout', link: '/logout', show: true },
      ],
      menuAtas: [
        {
          iconOn: 'start-up.svg',
          iconOff: 'start-up.svg',
          label: 'Project',
          link: '/project',
          show: this.state.showAccessProject,
        },
        { iconOn: 'party.svg', iconOff: 'party.svg', label: 'Event', link: '#' },
        {
          iconOn: 'bell.svg',
          iconOff: 'bell.svg',
          label: 'Notification',
          link: '/notification',
          isBadge: true,
          show: true,
        },
        {
          iconOn: 'calendar-2.svg',
          iconOff: 'calendar-2.svg',
          label: 'Calendar',
          link: '/calendar',
          show: this.state.showAccessCalendar,
        },
        {
          iconOn: 'open-folder.svg',
          iconOff: 'open-folder.svg',
          label: 'Files',
          link: '/files',
          show: this.state.showAccessFilemanager,
        },
        {
          iconOn: 'business-report.svg',
          iconOff: 'business-report.svg',
          label: 'Timeline',
          link: '/gantt/report',
          show: this.state.showAccessReport,
        },
        {
          iconOn: 'office-building.svg',
          iconOff: 'office-building.svg',
          label: 'Company',
          link: '/my-company',
          show: true,
        },
        {
          iconOn: 'envelope.svg',
          iconOff: 'envelope.svg',
          label: 'News',
          link: '/news',
          show: this.state.showAccessNews,
        },
        // { iconOn: 'mail-2.svg', iconOff: 'mail-2.svg', label: 'Announcement', link: '/pengumuman' },
      ],
      menuBawah: [
        // { iconOn: 'ptcon.svg', iconOff: 'ptcoff.svg', label: 'PTC', link: '/ptc' },
        { iconOn: 'settings.svg', iconOff: 'settings.svg', label: 'Setting', link: '/pengaturan', show: true },
        {
          iconOn: 'customer-service.svg',
          iconOff: 'customer-service.svg',
          label: 'Support',
          link: '/bantuan',
          show: true,
        },
        { iconOn: 'arrow.svg', iconOff: 'arrow.svg', label: 'Logout', link: '/logout', show: true },
      ],
    };

    let menuSuperAdmins = {
      submenu: [
        // { iconOn: 'open-folder.svg', iconOff: 'open-folder.svg', label: 'Files', link: '/files' },
        {
          iconOn: 'news-sidebar.svg',
          iconOff: 'news-sidebar.svg',
          label: 'News',
          link: '/news',
          show: this.state.showAccessNews,
        },
        {
          iconOn: 'open-folder.svg',
          iconOff: 'open-folder.svg',
          label: 'Project',
          link: '/project',
          show: this.state.showAccessProject,
        },
        {
          iconOn: 'info.svg',
          iconOff: 'info.svg',
          label: "User's Task Report",
          link: '/gantt/report',
          show: this.state.showAccessReport,
        },
        // { iconOn: 'materi.svg', iconOff: 'materi.svg', label: 'Kursus & Materi', link: '/kursus' },
        // { iconOn: 'forum.svg', iconOff: 'forum.svg', label: 'Forum', link: '/forum' },
        // { iconOn: 'conference.svg', iconOff: 'conference.svg', label: 'Group Meeting', link: '/meeting' },
        // { iconOn: 'kursus.svg', iconOff: 'kursus.svg', label: 'Manage Courses', link: '/kursus-materi' },
        // { iconOn: 'sertifikat.svg', iconOff: 'sertifikat.svg', label: 'Certificate', link: '/certificate' },
        // { iconOn: 'sertifikat.svg', iconOff: 'sertifikat.svg', label: 'Manage Certificates', link: '/certificate-admin' },
        {
          iconOn: 'company-sidebar.svg',
          iconOff: 'company-sidebar.svg',
          label: 'Company',
          link: '/company',
          show: true,
        },
        {
          iconOn: 'company-sidebar.svg',
          iconOff: 'company-sidebar.svg',
          label: 'My Company',
          link: '/my-company',
          show: true,
        },
        { iconOn: 'user-sidebar.svg', iconOff: 'user-sidebar.svg', label: 'Users', link: '/user', show: true },
        { iconOn: 'logout-sidebar.svg', iconOff: 'logout-sidebar.svg', label: 'Logout', link: '/logout', show: true },
      ],
      menuAtas: [
        {
          iconOn: 'start-up.svg',
          iconOff: 'start-up.svg',
          label: 'Project',
          link: '/project',
          show: this.state.showAccessProject,
        },
        { iconOn: 'party.svg', iconOff: 'party.svg', label: 'Event', link: '#' },
        {
          iconOn: 'bell.svg',
          iconOff: 'bell.svg',
          label: 'Notification',
          link: '/notification',
          isBadge: true,
          show: true,
        },
        {
          iconOn: 'calendar-2.svg',
          iconOff: 'calendar-2.svg',
          label: 'Calendar',
          link: '/calendar',
          show: this.state.showAccessCalendar,
        },
        {
          iconOn: 'open-folder.svg',
          iconOff: 'open-folder.svg',
          label: 'Files',
          link: '/files',
          show: this.state.showAccessFilemanager,
        },
        {
          iconOn: 'business-report.svg',
          iconOff: 'business-report.svg',
          label: 'Timeline',
          link: '/gantt/report',
          show: this.state.showAccessReport,
        },
        {
          iconOn: 'office-building.svg',
          iconOff: 'office-building.svg',
          label: 'Company',
          link: '/company',
          show: true,
        },
        {
          iconOn: 'envelope.svg',
          iconOff: 'envelope.svg',
          label: 'News',
          link: '/news',
          show: this.state.showAccessNews,
        },
        // { iconOn: 'mail-2.svg', iconOff: 'mail-2.svg', label: 'Announcement', link: '/pengumuman' },
      ],
      menuBawah: [
        // { iconOn: 'ptcon.svg', iconOff: 'ptcoff.svg', label: 'PTC', link: '/ptc' },
        { iconOn: 'settings.svg', iconOff: 'settings.svg', label: 'Setting', link: '/pengaturan', show: true },
        {
          iconOn: 'customer-service.svg',
          iconOff: 'customer-service.svg',
          label: 'Support',
          link: '/bantuan',
          show: true,
        },
        { iconOn: 'arrow.svg', iconOff: 'arrow.svg', label: 'Logout', link: '/logout', show: true },
      ],
    };

    const { menuAktif } = this.state;

    let menuContent = [];
    let menuAtas = [];
    let menuBawah = [];

    let tempAtasSuper = [],
      tempBawahSuper = [];
    let tempAtasAdmin = [],
      tempBawahAdmin = [];

    if (companyType === 'perusahaan') {
      tempAtasSuper = menuSuperAdmins.menuAtas.filter((item) => item.link != '/pengumuman');
      tempBawahSuper = menuSuperAdmins.menuBawah.filter((item) => item.link != '/ptc');

      tempAtasAdmin = menuAdmins.menuAtas.filter((item) => item.link != '/pengumuman');
      tempBawahAdmin = menuAdmins.menuBawah.filter((item) => item.link != '/ptc');
    } else {
      tempAtasSuper = menuSuperAdmins.menuAtas;
      tempBawahSuper = menuSuperAdmins.menuBawah;

      tempAtasAdmin = menuAdmins.menuAtas;
      tempBawahAdmin = menuAdmins.menuBawah;
    }

    if (levelUser === 'superadmin') {
      menuContent = menuSuperAdmins.submenu;
      menuAtas = tempAtasSuper;
      menuBawah = tempBawahSuper;
    } else if (levelUser === 'admin') {
      if (companyType === 'pendidikan') {
        menuContent = menuAdmins.submenuPendidikan;
      } else {
        menuContent = menuAdmins.submenu;
      }
      menuAtas = tempAtasAdmin;
      menuBawah = tempBawahAdmin;
    } else {
      let subMenuClient = Storage.get('user').data.grup_name
        ? Storage.get('user').data.grup_name.toString().toLowerCase()
        : '';
      if (
        subMenuClient === 'guru' ||
        subMenuClient === 'murid' ||
        subMenuClient === 'parents' ||
        subMenuClient === 'principal' ||
        subMenuClient === 'management'
      ) {
        menuContent = menuClients[subMenuClient].submenu;
        menuAtas = menuClients.menuAtas;
        menuBawah = menuClients.menuBawah;
      } else if (subMenuClient === 'user training' || subMenuClient === 'admin training') {
        menuContent = menuClients.submenuTraining;
        menuAtas = menuClients.menuAtasTraining;
        menuBawah = menuClients.menuBawahTraining;
      } else {
        menuContent = menuClients.other.submenu;
        menuAtas = menuClients.menuAtas;
        menuBawah = menuClients.menuBawah;
      }
    }
    const Loader = (props) => (
      <ContentLoader
        speed={2}
        width={380}
        height={610}
        viewBox="0 0 433 680"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="10" y="14" rx="12" ry="12" width="405" height="44" />
        <rect x="48" y="76" rx="12" ry="12" width="365" height="66" />
        <rect x="48" y="158" rx="12" ry="12" width="365" height="66" />
        <rect x="9" y="242" rx="12" ry="12" width="405" height="44" />
        <rect x="47" y="304" rx="12" ry="12" width="365" height="66" />
        <rect x="47" y="386" rx="12" ry="12" width="365" height="66" />
        <rect x="6" y="466" rx="12" ry="12" width="405" height="44" />
        <rect x="44" y="528" rx="12" ry="12" width="365" height="66" />
        <rect x="44" y="610" rx="12" ry="12" width="365" height="66" />
      </ContentLoader>
    );
    const EventLoader = (props) => (
      <ContentLoader
        speed={2}
        width={383}
        height={205}
        viewBox="0 0 383 205"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="13" y="34" rx="4" ry="4" width="24" height="24" />
        <circle cx="28" cy="73" r="7" />
        <rect x="56" y="38" rx="8" ry="8" width="318" height="18" />
        <rect x="56" y="64" rx="8" ry="8" width="223" height="18" />
        <rect x="57" y="91" rx="8" ry="8" width="149" height="18" />
        <rect x="312" y="91" rx="8" ry="8" width="61" height="18" />
        <rect x="11" y="127" rx="4" ry="4" width="24" height="24" />
        <circle cx="26" cy="166" r="7" />
        <rect x="54" y="131" rx="8" ry="8" width="318" height="18" />
        <rect x="54" y="157" rx="8" ry="8" width="223" height="18" />
        <rect x="55" y="184" rx="8" ry="8" width="149" height="18" />
        <rect x="310" y="184" rx="8" ry="8" width="61" height="18" />
      </ContentLoader>
    );
    let { project, search } = this.state;
    if (search != '') {
      project = project.filter((x) => JSON.stringify(Object.values(x)).match(new RegExp(search, 'gmi')));
    }
    return (
      <div className="sidebar-dummy" style={{ width: this.state.sidebarOpen ? '492px' : '59px' }}>
        <div className="pcoded-navbar2 navbar-collapsed2">
          {' '}
          {/** navbar-collapsed */}
          <div className="navbar-wrapper">
            <div className="navbar-brand header-logo sidebar-logo" style={{ width: '100%', marginTop: 14 }}>
              <Link
                to="/"
                target={
                  /^[meet\/]+[0-9]+$/.test(window.location.pathname) ||
                  /^[webinar\/]+[live\/]+[0-9]*$/.test(window.location.pathname)
                    ? '_blank'
                    : '_self'
                }
                rel="noopener noreferrer"
                className="b-brand"
                style={{ width: '100%' }}
                onClick={() => {
                  this.setState({ menuAktif: '/' });
                  closeMenuToggle();
                }}
              >
                <img
                  src={`assets/images/logo/${this.state.sideMenu ? 'Q-Logo-White.png' : 'Q-Logo-White.png'}`}
                  alt=""
                  style={{ width: '80%', height: 'auto', paddingLeft: '20%' }}
                />
                {/* <span className="b-title">IDEKU</span> */}
              </Link>

              {/* <a style={{cursor:'pointer'}} className="mobile-menu" id="mobile-collapse" ><span /></a> */}
            </div>

            {/* scroll-div */}
            <div className="navbar-content o-visible" style={{ background: 'none' }}>
              <ul className="nav pcoded-inner-navbar top-sidebar-container">
                {/* <li className="nav-item pcoded-menu-caption">
                  <label />
                </li> */}

                {menuAtas.map((item, i) => {
                  if (item.access == undefined || access[item.access]) {
                    if (item.label === 'Project' && item.show) {
                      return (
                        <li
                          key={i}
                          id="mobile-collapse"
                          data-username="Sample Page"
                          onClick={() => {
                            this.setState({ sidebarOpen: !this.state.sidebarOpen });
                          }}
                          className={`nav-item sidebar-item ${this.state.sidebarOpen && 'active'}`}
                          style={
                            this.state.sideMenu
                              ? { width: 59, cursor: 'pointer', position: 'relative' }
                              : { marginTop: 12, cursor: 'pointer' }
                          }
                        >
                          {/* <Tooltip title="Menu" arrow placement="right"> */}
                          <div
                            className="nav-link sidebar-item-link"
                            style={
                              this.state.sideMenu
                                ? {
                                    padding: '7px 0px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }
                                : { padding: '7px 20px' }
                            }
                          >
                            <span
                              className="pcoded-micon"
                              style={this.state.sideMenu ? { marginRight: 0, padding: 0, width: 'auto' } : null}
                            >
                              <img
                                className="sidebar-image"
                                src={`assets/new_ui/${menuAktif === item.link ? item.iconOn : item.iconOff}`}
                                alt=""
                                width={25}
                              ></img>
                            </span>
                            <span className="menu-item-sidebar">{item.label}</span>
                          </div>
                          {/* </Tooltip> */}
                        </li>
                      );
                    } else if (
                      item.label === 'Event' &&
                      (this.state.showAccessMeeting || this.state.showAccessWebinar || this.state.showAccessTraining)
                    ) {
                      return (
                        <Dropdown className="prevent-overflow">
                          <Dropdown.Toggle className="sidebar-event-menu">
                            <li
                              data-username="Sample Page"
                              className={`nav-item sidebar-item ${
                                (menuAktif === '/meeting' ||
                                  menuAktif === '/webinars' ||
                                  menuAktif.includes('/training')) &&
                                'active'
                              }`}
                              style={
                                this.state.sideMenu
                                  ? { width: 59, cursor: 'pointer', position: 'relative' }
                                  : { marginTop: 12, cursor: 'pointer' }
                              }
                            >
                              <div
                                className="nav-link sidebar-item-link"
                                style={
                                  this.state.sideMenu
                                    ? {
                                        marginTop: 18,
                                        padding: '8px 0px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 'auto',
                                        marginTop: '0px !important',
                                      }
                                    : { padding: '7px 20px' }
                                }
                              >
                                <span className="pcoded-micon" style={this.state.sideMenu ? { marginRight: 0 } : null}>
                                  <img
                                    className="sidebar-image"
                                    src={`assets/new_ui/${menuAktif === item.link ? item.iconOn : item.iconOff}`}
                                    alt=""
                                    width={25}
                                  ></img>
                                </span>
                                <span className="menu-item-sidebar">{item.label}</span>
                                {
                                  // item.hasOwnProperty('isBadge') ? <span className="badge-notif" style={this.state.notifUnread > 9 ? {padding: '1px 3px'} : {padding: '1px 6px'} }>{this.state.notifUnread}</span> : ''
                                  this.state.notifUnread && item.hasOwnProperty('isBadge') ? (
                                    <span
                                      className="badge-notif"
                                      style={
                                        this.state.notifUnread > 9 ? { padding: '1px 3px' } : { padding: '1px 6px' }
                                      }
                                    >
                                      {this.state.notifUnread}
                                    </span>
                                  ) : (
                                    ''
                                  )
                                }
                              </div>
                            </li>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="dropDownEvent sidebar">
                            {!this.state.showAccessMeeting ? null : (
                              <Dropdown.Item
                                href="meeting"
                                target={
                                  /^[meet\/]+[0-9]+$/.test(window.location.pathname) ||
                                  /^[webinar\/]+[live\/]+[0-9]*$/.test(window.location.pathname)
                                    ? '_blank'
                                    : '_self'
                                }
                                rel="noopener noreferrer"
                                style={{ paddingTop: 8, paddingBottom: 14 }}
                              >
                                <Tooltip title="Meeting" arrow placement="top">
                                  <div className="divIconMeeting icon">M</div>
                                </Tooltip>
                                Meeting
                              </Dropdown.Item>
                            )}
                            {!this.state.showAccessWebinar ? null : (
                              <Dropdown.Item
                                href="webinars"
                                target={
                                  /^[meet\/]+[0-9]+$/.test(window.location.pathname) ||
                                  /^[webinar\/]+[live\/]+[0-9]*$/.test(window.location.pathname)
                                    ? '_blank'
                                    : '_self'
                                }
                                rel="noopener noreferrer"
                                style={{ paddingTop: 8, paddingBottom: 14 }}
                              >
                                <Tooltip title="Webinar" arrow placement="top">
                                  <div className="divIconWebinar icon webinar">W</div>
                                </Tooltip>
                                Webinar
                              </Dropdown.Item>
                            )}
                            {!this.state.showAccessTraining ? null : (
                              <Dropdown.Item
                                href={
                                  featureQuota
                                    ? 'training'
                                    : Storage.get('user').data.level == 'client'
                                    ? 'training/plan-user'
                                    : 'training/company'
                                }
                                target={
                                  /^[meet\/]+[0-9]+$/.test(window.location.pathname) ||
                                  /^[webinar\/]+[live\/]+[0-9]*$/.test(window.location.pathname)
                                    ? '_blank'
                                    : '_self'
                                }
                                rel="noopener noreferrer"
                                style={{ paddingTop: 8, paddingBottom: 14 }}
                              >
                                <Tooltip title="Training" arrow placement="top">
                                  <div className="divIconTraining icon">T</div>
                                </Tooltip>
                                Training
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      );
                    } else {
                      if (item.show) {
                        return (
                          <li
                            data-username="Sample Page"
                            className={`nav-item sidebar-item ${menuAktif === item.link && 'active'}`}
                            style={
                              this.state.sideMenu
                                ? { width: 59, cursor: 'pointer', position: 'relative' }
                                : { marginTop: 12, cursor: 'pointer' }
                            }
                          >
                            {/* <Tooltip title={item.label} arrow placement="right"> */}
                            <Link
                              className="nav-link sidebar-item-link"
                              to={item.link}
                              target={
                                /^[meet\/]+[0-9]+$/.test(window.location.pathname) ||
                                /^[webinar\/]+[live\/]+[0-9]*$/.test(window.location.pathname)
                                  ? '_blank'
                                  : '_self'
                              }
                              rel="noopener noreferrer"
                              onClick={() => {
                                this.setState({ menuAktif: item.link, sidebarOpen: false });
                                closeMenuToggle();
                              }}
                              style={
                                this.state.sideMenu
                                  ? {
                                      marginTop: 18,
                                      padding: 0,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      width: 'auto',
                                    }
                                  : { padding: '7px 20px' }
                              }
                            >
                              <span className="pcoded-micon" style={this.state.sideMenu ? { marginRight: 0 } : null}>
                                <img
                                  className="sidebar-image"
                                  src={`assets/new_ui/${menuAktif === item.link ? item.iconOn : item.iconOff}`}
                                  alt=""
                                  width={25}
                                ></img>
                              </span>
                              <span className="menu-item-sidebar">{item.label}</span>
                              {
                                // item.hasOwnProperty('isBadge') ? <span className="badge-notif" style={this.state.notifUnread > 9 ? {padding: '1px 3px'} : {padding: '1px 6px'} }>{this.state.notifUnread}</span> : ''
                                this.state.notifUnread && item.hasOwnProperty('isBadge') ? (
                                  <span
                                    className="badge-notif"
                                    style={this.state.notifUnread > 9 ? { padding: '1px 3px' } : { padding: '1px 6px' }}
                                  >
                                    {this.state.notifUnread}
                                  </span>
                                ) : (
                                  ''
                                )
                              }
                            </Link>
                            {/* </Tooltip> */}
                          </li>
                        );
                      }
                    }
                  }
                })}
              </ul>
              <ul className="nav pcoded-inner-navbar" style={{ position: 'fixed', display: 'block', bottom: '30px' }}>
                <span className="position-absolute w-100 text-center" style={{ bottom: -20, fontSize: '9px' }}>
                  {this.state.webVersion ? `v.${this.state.webVersion}` : ''}
                </span>
                {menuBawah.map((item, i) => {
                  if (item.access == undefined || access[item.access]) {
                    return (
                      <li
                        data-username="Sample Page"
                        className={`nav-item sidebar-item ${menuAktif === item.link && 'active'}`}
                        style={
                          this.state.sideMenu
                            ? { width: 59, cursor: 'pointer', float: 'left', clear: 'both' }
                            : { marginTop: 12, cursor: 'pointer' }
                        }
                      >
                        {/* <Tooltip title={item.label} arrow placement="right"> */}
                        <Link
                          to={item.link}
                          target={
                            /^[meet\/]+[0-9]+$/.test(window.location.pathname) ||
                            /^[webinar\/]+[live\/]+[0-9]*$/.test(window.location.pathname) ||
                            item.link === '/bantuan'
                              ? item.link === '/logout'
                                ? '_self'
                                : '_blank'
                              : '_self'
                          }
                          rel="noopener noreferrer"
                          className="nav-link sidebar-item-link"
                          onClick={() => {
                            this.setState({ menuAktif: item.link, sidebarOpen: false });
                            closeMenuToggle();
                          }}
                          style={
                            this.state.sideMenu
                              ? {
                                  padding: 0,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: 'auto',
                                }
                              : { padding: '7px 20px' }
                          }
                        >
                          <span className="pcoded-micon" style={this.state.sideMenu ? { marginRight: 0 } : null}>
                            <img
                              className="sidebar-image"
                              src={`assets/new_ui/${menuAktif === item.link ? item.iconOn : item.iconOff}`}
                              alt=""
                              width={25}
                            ></img>
                          </span>
                          <span className="menu-item-sidebar">{item.label}</span>
                        </Link>
                        {/* </Tooltip> */}
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          <div className={'custom-side-bar ' + (this.state.sidebarOpen ? 'isSidebarOpen' : 'isSidebarClose')}>
            <div className="side-submenu-container" style={{ marginTop: 28 }}>
              <div className="side-submenu-title">Project</div>
            </div>
            <div className="side-submenu-container">
              <img className="search-icon" src="assets/new_ui/search.svg" alt="" />
              <input
                type="text"
                name="search"
                style={accessAddProject ? null : { width: 384 }}
                value={this.state.search}
                onChange={(e) => this.setState({ search: e.target.value })}
                className="side-submenu-search"
                placeholder="Search..."
              />
              {accessAddProject && cdProject && (
                <button className="side-add-button" onClick={(e) => this.clickAddProject()}>
                  <img className="icon-button" src="assets/new_ui/plus.svg" alt="" /> Add Project
                </button>
              )}
            </div>
            {this.state.isLoading ? (
              <div className="side-submenu-container p-r-25 m-t-26">
                <Loader />
              </div>
            ) : (
              <>
                {project.map((item) => {
                  return (
                    <div className="side-submenu-container p-r-25">
                      <div className="side-submenu-item">
                        <div className="side-submenu-head" onClick={(e) => this.openProject(item.project_id)}>
                          {item.title}
                          <img
                            className={`item-arrow-${item.open ? 'down' : 'right'}`}
                            src={`assets/new_ui/arrow-${item.open ? 'down' : 'right'}.svg`}
                            alt=""
                          />
                          {item.open && (
                            <>
                              {/* <Tooltip title="Delete" arrow placement="top">
                                <img
                                  className="item-icon"
                                  src="assets/new_ui/trash.svg"
                                  onClick={(e) => this.clickDelete(e, item.project_id, item.title)}
                                />
                              </Tooltip> */}
                              {!accessProjectManager ? null : (
                                <Tooltip title="Setting" arrow placement="top">
                                  <img
                                    className="item-icon"
                                    src="assets/new_ui/setting-item.svg"
                                    onClick={(e) => this.clickEdit(e, item.project_id)}
                                    alt=""
                                  />
                                </Tooltip>
                              )}
                              <Tooltip title="Refresh" arrow placement="top">
                                <img
                                  className="item-icon"
                                  src="assets/new_ui/refresh.svg"
                                  onClick={(e) => this.getProject(item.project_id)}
                                  alt=""
                                />
                              </Tooltip>
                            </>
                          )}
                        </div>
                        {item.open && (
                          <>
                            <div
                              className="sidebar-event-container"
                              style={{ maxHeight: item.more || (item.event && item.event.length < 6) ? 90000 : 340 }}
                            >
                              {item.loading ? (
                                <EventLoader />
                              ) : (
                                item.event &&
                                item.event.map((event) => (
                                  <Link
                                  className="side-submenu-event-container m-t-30"
                                  to={this.openLink.bind(this, event, item)}
                                  target={'_blank'}
                                  >
                                    <div className="event-title">
                                      <Tooltip title={event?.type} arrow placement="top">
                                        <div className={`event-symbol ${event?.type.toLowerCase()}`}>
                                          {event.type.substring(0, 1).toUpperCase()}
                                        </div>
                                      </Tooltip>
                                      {event?.room_name || event?.title || event?.judul}
                                    </div>
                                    <div className="event-schedule">
                                      <div
                                        className={`event-indicator ${
                                          event.status === 'On Going' || (event.status === 'Active' && 'active')
                                        }`}
                                      ></div>
                                      {event.schedule_start !== null && event.schedule_end !== null
                                        ? `${moment(event.schedule_start).format('DD/MM/YYYY | HH:mm A - ')}${moment(
                                            event.schedule_end,
                                          ).format('HH:mm A')}`
                                        : 'No Schedule'}
                                    </div>
                                    <div
                                      className="event-participant"
                                      style={{
                                        marginLeft: event.participants && event.participants.length > 0 ? 'auto' : 38,
                                      }}
                                    >
                                      <div className="event-participant-image">
                                        {event.participants &&
                                          event.participants.length > 0 &&
                                          event.participants.slice(0, 3).map((item) => (
                                            <Tooltip title={item.name} arrow placement="top">
                                              <img
                                                src={
                                                  item.image
                                                    ? item.image
                                                    : avatar[Math.floor(Math.random() * avatar.length)]
                                                }
                                                alt="image"
                                              />
                                            </Tooltip>
                                          ))}
                                      </div>
                                      {event.participants && event.participants.length > 0
                                        ? event.participants.length
                                        : 'No'}{' '}
                                      {event.participants && event.participants.length > 1
                                        ? 'Participants'
                                        : 'Participant'}
                                      <div
                                        className={`event-status ${
                                          event.status === 'Empty' || event.status === 'Finish'
                                            ? 'grey'
                                            : event.status === 'Upcoming' || event.status === 'Not Started'
                                            ? 'blue'
                                            : event.status === 'Incomplete'
                                            ? 'yellow'
                                            : event.status === 'On Going' || event.status === 'Active'
                                            ? 'green'
                                            : 'blue'
                                        }`}
                                      >
                                        {event.status === 'Active' ? 'On Going' : event.status}
                                      </div>
                                    </div>
                                  </Link>
                                ))
                              )}
                              {!item.more && item.event && item.event.length > 5 && !item.loading && (
                                <div className="sidebar-event-overlay"></div>
                              )}
                            </div>
                            {!item.more && item.event && item.event.length > 5 && !item.loading && (
                              <div style={{ textAlign: 'center' }}>
                                <span
                                  className="show-more-event"
                                  onClick={() => {
                                    item.more = true;
                                    this.forceUpdate();
                                  }}
                                >
                                  Show More
                                  <br />
                                  <i className="fa fa-chevron-down"></i>
                                </span>
                              </div>
                            )}
                            {/* <button
                              className="side-add-button light"
                              onClick={(e) => this.clickAddEvent(e, item.project_id)}
                            >
                              <img className="icon-button" src="assets/new_ui/plus-blue.svg" /> Add Event
                            </button> */}
                            {(!this.state.showAccessMeeting && !this.state.showAccessWebinar) ||
                            (!cdWebinar && !cdMeeting) ||
                            (!this.state.limitCompany.meeting && !this.state.limitCompany.webinar) ||
                            (!cdWebinar && !this.state.limitCompany.meeting) ||
                            (!this.state.limitCompany.meeting && !cdMeeting) ||
                            !item.is_project_admin ? null : (
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  className="side-add-button light"
                                  style={{
                                    background: 'transparent',
                                    color: '#0091FF',
                                    borderColor: '#0091FF',
                                    boxShadow: 'none',
                                  }}
                                >
                                  <img className="icon-button" src="assets/new_ui/plus-blue.svg" /> Add Event
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropDownEvent">
                                  {!this.state.showAccessMeeting ||
                                  !cdMeeting ||
                                  !this.state.limitCompany.meeting ? null : (
                                    <Dropdown.Item
                                      style={{ paddingTop: 8, paddingBottom: 14 }}
                                      onClick={this.toogleModalMeeting.bind(this)}
                                    >
                                      <Tooltip title="Meeting" arrow placement="top">
                                        <div className="divIconMeeting icon">M</div>
                                      </Tooltip>
                                      Meeting
                                    </Dropdown.Item>
                                  )}
                                  {!this.state.showAccessWebinar ||
                                  !cdWebinar ||
                                  !this.state.limitCompany.webinar ? null : (
                                    <Dropdown.Item
                                      style={{ paddingTop: 8, paddingBottom: 14 }}
                                      onClick={() => window.open(`/webinar/create/${item.project_id}/default`)}
                                    >
                                      <Tooltip title="Webinar" arrow placement="top">
                                        <div className="divIconWebinar icon webinar">W</div>
                                      </Tooltip>
                                      Webinar
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            )}

                            <ModalAddEvent
                              show={this.state.showModalMeeting}
                              onHide={this.toogleModalMeeting.bind(this)}
                              projectId={item.project_id}
                            />
                            {this.state.showAccessMeeting ||
                            this.state.showAccessWebinar ||
                            this.state.showAccessReport && (
                              <a href={`/detail-project/${item.project_id}/default`}>
                                <div className="side-sub-item">
                                  <img src="assets/new_ui/documents.svg" alt="" /> Documents
                                </div>
                              </a>
                            )}
                            {this.state.showAccessReport && (
                              <a href={`/detail-project/${item.project_id}/default`}>
                                <div className="side-sub-item" style={{ marginBottom: 20 }}>
                                  <img src="assets/new_ui/timeline.svg" alt="" /> Timeline
                                </div>
                              </a>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {/* <div>
            {
              menuContent.map((item, i) => {
                if (item.access == undefined || access[item.access]) {
                  return (
                    <div>
                      {item.label === 'News' && <div className="side-submenu-groupname">MENU</div>}
                      {item.label === 'Logout' && <div className="side-submenu-groupname">SESSION</div>}
                      {item.label === 'My Company' && <div className="side-submenu-groupname">{localStorage.getItem("companyName") ? localStorage.getItem("companyName").toUpperCase() : this.state.companyName.toUpperCase()}</div>}
                      <Link to={item.link} style={{ color: '#797979' }}>
                        <div className="side-submenu">
                          <img
                            src={`newasset/${menuAktif === item.link ? item.iconOn : item.iconOff}`}
                            style={{ marginRight: 15 }}
                            alt=""
                            height={15}
                            width={15}
                          ></img>
                          {item.label}
                        </div>
                      </Link>
                    </div>
                  )
                }
              })
            }
          </div> */}
          </div>
        </div>
        <Modal
          show={this.state.modalAddProject}
          onHide={this.closeModalProject}
          dialogClassName="rounded-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-c-purple3 f-w-bold" style={{ color: '#263866' }}>
              Add new Project
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className="cardku no-border">
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group controlId="formJudul">
                      <Form.Label className="f-w-bold">Project Name</Form.Label>
                      <div className="input-group mb-4">
                        <input
                          type="text"
                          name="folderName"
                          value={this.state.folderName}
                          className="form-control"
                          placeholder="Project Name"
                          onChange={this.onChangeInput}
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formJudul">
                      <Form.Label className="f-w-bold">Project Admin</Form.Label>
                      <Select
                        options={this.state.optionsProjectAdmin}
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(valueProjectAdmin) => {
                          let arr = [];
                          valueProjectAdmin.map((item) => arr.push(item.value));
                          this.setState({ valueProjectAdmin: arr });
                        }}
                      />
                      <Form.Text className="text-muted">
                        Project admins can manage meetings, webinars, and files.
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formJudul">
                      <Form.Label className="f-w-bold">Limit Users</Form.Label>
                      <div style={{ width: '100%' }}>
                        <ToggleSwitch onChange={this.toggleSwitch.bind(this)} checked={this.state.limited} />
                      </div>
                      <Form.Text className="text-muted">
                        {this.state.limited
                          ? 'Only below listed people can access the project as participants can access the project.'
                          : 'All people can access this project'}
                      </Form.Text>
                    </Form.Group>
                    {this.state.limited && (
                      <Form.Group controlId="formJudul">
                        <Form.Label className="f-w-bold">Users</Form.Label>
                        <Select
                          options={this.state.optionsProjectAdmin}
                          isMulti
                          closeMenuOnSelect={false}
                          onChange={(valueUser) => {
                            let arr = [];
                            valueUser.map((item) => arr.push(item.value));
                            this.setState({ valueUser: arr });
                          }}
                        />
                      </Form.Group>
                    )}
                    {this.state.limited && (
                      <Form.Group controlId="formJudul">
                        <Form.Label className="f-w-bold">Department</Form.Label>
                        <Select
                          options={this.state.branchCompany}
                          isMulti
                          closeMenuOnSelect={false}
                          onChange={(valueBranch) => {
                            let choosenUserBasedOnBranch = [];
                            valueBranch.forEach((data) => {
                              const filterUser = this.state.allUser.filter((val) => val.branch_name === data.label);
                              choosenUserBasedOnBranch = choosenUserBasedOnBranch.concat(filterUser);
                            });

                            let forDefaultUser = [];

                            choosenUserBasedOnBranch = choosenUserBasedOnBranch.map((val) => {
                              forDefaultUser.push({
                                value: val.user_id,
                                label: val.name,
                              });

                              return val.user_id;
                            });

                            this.setState({
                              valueUser: choosenUserBasedOnBranch,
                              defaultUsers: forDefaultUser,
                              defaultBranch: valueBranch,
                            });
                            this.forceUpdate();
                          }}
                          value={this.state.defaultBranch}
                          defaultValue={this.state.defaultBranch}
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>
              </Card.Body>
              <Modal.Footer>
                <button
                  className="btn btm-icademy-primary btn-icademy-grey"
                  onClick={this.closeModalProject.bind(this)}
                >
                  Close
                </button>
                <button className="btn btn-icademy-primary" onClick={this.saveProject.bind(this)}>
                  <i className="fa fa-save"></i>
                  Save
                </button>
              </Modal.Footer>
            </Card>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.modalEdit} onHide={this.closeModalEdit} dialogClassName="modal-lg rounded-modal">
          <Modal.Header closeButton>
            <Modal.Title className="text-c-purple3 f-w-bold" style={{ color: '#263866' }}>
              Project Settings
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className="cardku no-border">
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group controlId="formJudul">
                      <Form.Label className="f-w-bold">Project Name</Form.Label>
                      <div className="input-group mb-4">
                        <input
                          type="text"
                          name="editProjectName"
                          value={this.state.editProjectName}
                          className="form-control"
                          placeholder="Project Name"
                          onChange={this.onChangeInput}
                          required
                        />
                      </div>
                      <div style={{ color: '#F00' }}>{this.state.alert}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formJudul">
                      <Form.Label className="f-w-bold">Project Admin</Form.Label>
                      <Select
                        options={this.state.optionsProjectAdmin}
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(valueProjectAdmin) => {
                          let arr = [];
                          valueProjectAdmin.map((item) => arr.push(item.value));
                          this.setState({ valueProjectAdmin: arr });
                        }}
                        defaultValue={this.state.defaultProjectAdmin}
                      />
                      <Form.Text className="text-muted">
                        Project admins can manage meetings, webinars, and files.
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formJudul">
                      <Form.Label className="f-w-bold">Limit Users</Form.Label>
                      <div style={{ width: '100%' }}>
                        <ToggleSwitch onChange={this.toggleSwitch.bind(this)} checked={this.state.limited} />
                      </div>
                      <Form.Text className="text-muted">
                        {this.state.limited
                          ? 'Only below listed people can access the project as participants can access the project.'
                          : 'All people can access this project'}
                      </Form.Text>
                    </Form.Group>
                    {this.state.limited && (
                      <Form.Group controlId="formJudul">
                        <Form.Label className="f-w-bold">User</Form.Label>
                        <Select
                          options={this.state.optionsProjectAdmin}
                          isMulti
                          closeMenuOnSelect={false}
                          onChange={(valueUser) => {
                            let arr = [];
                            valueUser.map((item) => arr.push(item.value));
                            this.setState({ valueUser: arr, defaultUsers: valueUser });
                          }}
                          value={this.state.defaultUsers}
                          defaultValue={this.state.defaultUsers}
                        />
                      </Form.Group>
                    )}
                    {this.state.limited && (
                      <Form.Group controlId="formJudul">
                        <Form.Label className="f-w-bold">Department</Form.Label>
                        <Select
                          options={this.state.branchCompany}
                          isMulti
                          closeMenuOnSelect={false}
                          onChange={(valueBranch) => {
                            let choosenUserBasedOnBranch = [];
                            valueBranch.forEach((data) => {
                              const filterUser = this.state.allUser.filter((val) => {
                                if (val.branch_name) {
                                  if (val.branch_name.includes(',')) {
                                    const splitBranch = val.branch_name.split(',');
                                    if (splitBranch.includes(data.label)) return true;
                                  } else {
                                    if (val.branch_name === data.label) return true;
                                  }
                                }
                                return false;
                              });

                              choosenUserBasedOnBranch = choosenUserBasedOnBranch.concat(filterUser);
                            });

                            let forDefaultUser = [];

                            choosenUserBasedOnBranch = choosenUserBasedOnBranch.map((val) => {
                              forDefaultUser.push({
                                value: val.user_id,
                                label: val.name,
                              });

                              return val.user_id;
                            });

                            this.setState({
                              valueUser: choosenUserBasedOnBranch,
                              defaultUsers: forDefaultUser,
                              defaultBranch: valueBranch,
                            });
                            this.forceUpdate();
                          }}
                          value={this.state.defaultBranch}
                          defaultValue={this.state.defaultBranch}
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>
                <Card className="cardku">
                  <Card.Body>
                    <Row>
                      <Col>
                        <div class="row">
                          <div className="col-sm-8">
                            <Form.Label className="f-w-bold">
                              Sharing this project to other users outside your company.
                            </Form.Label>
                            <input
                              type="email"
                              required
                              name="email"
                              value={this.state.email}
                              className="form-control"
                              placeholder="user's email. e.g : youremail@host.com"
                              onChange={this.onChangeInput}
                            />
                            <Form.Text className="text-muted">
                              The destination email must be registered as an QONNECTIQ user{' '}
                            </Form.Text>
                            {this.state.alertSharing ? (
                              <Form.Text className="text-red">{this.state.alertSharing}</Form.Text>
                            ) : null}
                          </div>
                          <div className="col-sm-4">
                            <button
                              className="btn btn-icademy-primary"
                              style={{ marginTop: 25 }}
                              onClick={this.share.bind(this)}
                            >
                              <i className="fa fa-share-alt"></i>
                              Share
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.share.length > 0 ? (
                              this.state.share.map((item) => (
                                <tr>
                                  <td>{item.name}</td>
                                  <td>{item.email}</td>
                                  <td>
                                    <i
                                      onClick={this.deleteShare.bind(this, item.id)}
                                      style={{ cursor: 'pointer' }}
                                      className="fa fa-trash"
                                    ></i>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colspan="3">No data sharing</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            {!cdProject ? null : (
              <button
                className="btn btm-icademy-primary btn-icademy-grey"
                onClick={(e) => this.clickDelete(e, this.state.deleteProjectId, this.state.deleteProjectName)}
              >
                <i className="fa fa-trash"></i>
                Delete
              </button>
            )}
            <button className="btn btm-icademy-primary btn-icademy-grey" onClick={this.closeModalEdit.bind(this)}>
              Close
            </button>
            <button className="btn btn-icademy-primary" onClick={this.editProject.bind(this)}>
              <i className="fa fa-save"></i>
              Save
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.modalDelete} onHide={this.closeModalDelete} centered className="rounded-modal">
          <Modal.Header closeButton>
            <Modal.Title className="text-c-purple3 f-w-bold" style={{ color: '#263866' }}>
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Are you sure you want to delete the project <b>{this.state.deleteProjectName}</b> ?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btm-icademy-primary btn-icademy-grey" onClick={this.closeModalDelete.bind(this)}>
              Cancel
            </button>
            <button className="btn btn-icademy-primary btn-icademy-red" onClick={this.deleteProject.bind(this, true)}>
              <i className="fa fa-trash"></i>
              Delete
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.modalForceDelete}
          onHide={this.closeModalForceDelete}
          centered
          className="rounded-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-c-purple3 f-w-bold" style={{ color: '#263866' }}>
              Warning
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              The project you are going to delete has several files.
              <br />
              Are you sure you want to force delete the project <b>{this.state.deleteProjectName}</b> ?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btm-icademy-primary btn-icademy-grey"
              onClick={this.closeModalForceDelete.bind(this)}
            >
              Cancel
            </button>
            <button className="btn btn-icademy-primary btn-icademy-red" onClick={this.deleteProject.bind(this, false)}>
              <i className="fa fa-trash"></i>
              Yes, Delete
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const Sidebar = (props) => (
  <SocketContext.Consumer>{(socket) => <SidebarClass {...props} socket={socket} />}</SocketContext.Consumer>
);

export default withRouter(Sidebar);
