import React, { Component } from 'react';
import API, { API_SERVER, USER_ME } from '../../repository/api';
import Storage from '../../repository/storage';
import { Modal } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';

class ModalAddEvent extends Component {
  state = {
    limitCompany: [],
    companyId: '',
  };

  openLinkMeeting = (isOpenModalMeeting, projectId) => {
    const hasProjectId = !!projectId;
    if (hasProjectId) {
      window.open(`/detail-project/${projectId}/default?isOpenModalMeeting=${isOpenModalMeeting}`, '_self');
    } else {
      window.open(`/meeting?isOpenModalMeeting=${isOpenModalMeeting}`, '_self');
    }
  };
  checkLimitCompany() {
    API.get(`${API_SERVER}v2/company-limit/${this.state.companyId}`).then((res) => {
      if (res.status === 200) {
        if (!res.data.error) {
          this.setState({ limitCompany: res.data.result });
        } else {
          console.log('Error check limit company');
        }
      }
    });
  }
  componentDidMount() {
    API.get(`${USER_ME}${Storage.get('user').data.email}`).then((res) => {
      this.setState(
        {
          companyId: localStorage.getItem('companyID') ? localStorage.getItem('companyID') : res.data.result.company_id,
        },
        () => {
          this.checkLimitCompany();
        },
      );
    });
  }

  render() {
    const DivContentExistingMeetingRoom = styled.div`
      box-sizing: border-box;
      height: 39px;
      border: 0.98px solid #0091ff;
      border-radius: 10px;
      background-color: #ecf6ff;
      margin-bottom: 20px;
      cursor: pointer;
    `;
    const DivExistingMeetingRoom = styled.div`
      height: 19px;
      color: #0091ff;
      font-family: 'Segoe UI';
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      padding: 10px;
    `;

    const DivContentNewMeetingRoom = styled.div`
      height: 39px;
      border-radius: 10px;
      background-color: #0072d8;
      margin-bottom: 20px;
      cursor: pointer;
    `;

    const DivNewMeetingRoom = styled.div`
      height: 19px;
      color: #ffffff;
      font-family: 'Segoe UI';
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      padding: 10px;
    `;

    return (
      <Modal show={this.props.show} onHide={this.props.onHide} centered>
        <Modal.Body style={{ borderRadius: '14px' }}>
          <Modal.Title
            className="modal-title p-t-0 f-21 f-w-bold text-c-black"
            style={{ textAlign: 'center', color: '#007AD7' }}
          >
            {' '}
            <Tooltip title="Meeting" arrow placement="top">
              <div className="divIconMeeting icon" style={{ display: 'inline', float: 'none' }}>
                M
              </div>
            </Tooltip>
            Meeting{' '}
          </Modal.Title>
          <div className="modal-body">
            <DivContentExistingMeetingRoom>
              <DivExistingMeetingRoom onClick={this.openLinkMeeting.bind(this, 0, this.props.projectId)}>
                Create schedule in existing meeting room
              </DivExistingMeetingRoom>
            </DivContentExistingMeetingRoom>
            {!this.state.limitCompany.meeting ? null : (
              <DivContentNewMeetingRoom onClick={this.openLinkMeeting.bind(this, 1, this.props.projectId)}>
                <DivNewMeetingRoom>Create new meeting room</DivNewMeetingRoom>
              </DivContentNewMeetingRoom>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalAddEvent;
