import React, { lazy } from 'react';
import { connect } from 'react-redux';
import './actions/i18n';

import { Switch, Route, Redirect } from 'react-router-dom';

import API, { API_SERVER, API_SOCKET } from './repository/api';
import Storage from './repository/storage';

import io from 'socket.io-client';

import Header from './components/Header_sidebar/Header';
import Sidebar from './components/Header_sidebar/Sidebar';
import Loader from './components/Header_sidebar/Loader';

//gantt public
import Login from './components/Login/index';

import ForgotPassword from './components/forgotPassword';
import OTP from './components/OTP';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-sm-select/dist/styles.css';
import { testRedux } from './redux/actions';

import SocketContext from './socket';
const socket = io(`${API_SOCKET}`,{ transports: ['websocket'], query: { actor: 'web', transports: ['websocket'] } });
socket.on("connect", () => {
  // console.log("Loading App");
});
const ClientSwitch = lazy(() => import('./routes/client'));
const AdminSwitch = lazy(() => import('./routes/admin'));
const SuperAdminSwitch = lazy(() => import('./routes/superadmin'));

const MeetingRoomPublic = lazy(() => import('./components/liveclass/meetingRoomPublic'));
const MeetRoomPub = lazy(() => import('./components/liveclass/meetRoomPub'));
const GanttPublic = lazy(() => import('./components/Gantt/GanttPublic'));
const MobileMeeting = lazy(() => import('./components/liveclass/mobileMeeting'));
const WebinarLivePublic = lazy(() => import('./components/client/webinar/livePublic'));
const ThankYou = lazy(() => import('./components/public/thankyou'));
const MeetingRoom = lazy(() => import('./components/liveclass/meetingRoom'));
const ElectronPage = lazy(() => import('./components/electron/landingPage'));
const PageRegistrationWebinar = lazy(() => import('./components/client/webinar/registration'));
const RegistrationTraining = lazy(() => import('./components/training/company/registration'));
const RegistrationCustomePublic = lazy(() => import('./components/public/registrationCustome'));

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userLogin: false,
      desktopApp: localStorage.getItem("desktopApp"),
      endMeet: window.location.href.search("thankyou") > -1 ? true : false,
    };
  }

  componentDidMount() {
    let userInfo = localStorage.getItem('user');

    if (userInfo == null) {
      this.setState({ userLogin: false });
    } else {
      this.setState({ userLogin: true });
    }

    if (window.location.href.search('electron') > -1) {
      if (this.state.desktopApp) {
        this.setState({ desktopApp: true });
      } else {
        localStorage.setItem('desktopApp', true);
        this.setState({ desktopApp: true });
      }
    } else {
      if (this.state.desktopApp) {
        this.setState({ desktopApp: true });
      } else {
        this.setState({ desktopApp: false });
      }
    }

  }

  render() {
    let workSpace = null;
    if (this.state.userLogin) {
      workSpace = <Main />;
    } else {
      workSpace = <PublicContent />;
    }

    return <div>{workSpace}</div>;
  }
}

const stateProps = (state, ownProps) => {
  return {
    test: state.app.test,
  };
};

const dispatchProps = (dispatch) => {
  return {
    dispatchTestRedux: (data) => dispatch(testRedux(data)),
  };
};

export default connect(stateProps, dispatchProps)(App);

export class PublicContent extends React.Component {
  render() {
    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/meeting/:roomid" exact component={MeetingRoomPublic} />
          <Route path="/meet/:roomid" exact component={MeetRoomPub} />
          <Route path="/gantt/:companyId/:projectId/:userId" exact component={GanttPublic} />
          <Route path="/webinar-guest/:webinarId/:voucher" exact component={WebinarLivePublic} />
          <Route path="/mobile-meeting/:url+" exact component={MobileMeeting} />
          <Route path="/redirect/:url+" exact component={RedirectPage} />
          <Route path="/meeting-room/:roomid" exact component={RedirectPageMeeting} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/OTP/:id" component={OTP} />
          <Route path="/reset-password/:id/:key" component={Login} />
          <Route path="/thankyou" exact component={ThankYou} />
          <Route path="/electron/landingpage" component={ElectronPage} />
          <Route path="/registration/webinars/:id" component={PageRegistrationWebinar} />
          <Route path="/training/registration/:id" component={RegistrationTraining} />
          <Route path="/training/form-registration/:id" component={RegistrationCustomePublic} />
          <Route component={Login} />
        </Switch>
      </div>
    );
  }
}

export class RedirectPage extends React.Component {
  render() {
    let userInfo = localStorage.getItem('user');
    if (userInfo) {
      return <Redirect to={'/' + this.props.match.params.url} />;
    } else {
      return <Login redirectUrl={'/' + this.props.match.params.url} />;
    }
  }
}

export class RedirectPageMeeting extends React.Component {
  render() {
    let userInfo = localStorage.getItem('user');
    if (!userInfo) {
      return <Login redirectUrl={'/meeting-room/' + this.props.match.params.roomid} />;
    } else {
      return <Route path="/meeting-room/:roomid" component={MeetingRoom} />;
    }
  }
}

export class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      level: Storage.get('user').data.level,
      desktopApp: localStorage.getItem('desktopApp'),
      endMeet: window.location.href.search("thankyou") > -1 ? true : false,
      openMenu: false,
    }
  }

  componentDidMount() {
    if (window.location.href.search('electron') > -1) {
      if (this.state.desktopApp) {
        this.setState({ desktopApp: true });
      } else {
        localStorage.setItem('desktopApp', true);
        this.setState({ desktopApp: true });
      }
    } else {
      if (this.state.desktopApp) {
        this.setState({ desktopApp: true });
      } else {
        this.setState({ desktopApp: false });
      }
    }
  }

  handleScroll = () => {
    if (this.state.openMenu == true) {
      this.setState({openMenu: false})
    }
  }

  menuToggle = () => {
    this.setState({openMenu: !this.state.openMenu});
  }

  closeMenuToggle = () => {
    this.setState({openMenu: false});
  }

  render() {
    // console.log(window.history.length,'????')
    sessionStorage.clear();
    if (typeof Storage !== "undefined") {
      if (!sessionStorage.new_tab && window.history.length <= 1) {
        sessionStorage.new_tab = true;
      }
    } else {
      // console.log("Sorry, your browser does not support web storage...");
    }

    // console.log(window.history,'199 hisotyr')
    let workSpaceSwitch = null;
    if (this.state.level === 'superadmin') {
      workSpaceSwitch = <SuperAdminSwitch />;
    } else if (this.state.level === 'admin') {
      workSpaceSwitch = <AdminSwitch />;
    } else {
      workSpaceSwitch = <ClientSwitch />;
    }

    return (
      <div className="main-container">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SocketContext.Provider value={socket}>
          <Loader />
          <div className={'left-container ' + (this.state.openMenu ? 'isOpen' : 'isClose')}>
            {this.state.desktopApp || window.location.pathname === '/thankyou' ? null : <Sidebar closeMenuToggle={this.closeMenuToggle} />}
          </div>
          <div className="right-container" onScroll={this.handleScroll}>
            {this.state.desktopApp || window.location.pathname === '/thankyou' ? null : <Header menuToggle={this.menuToggle} openMenu={this.state.openMenu} />}

            {workSpaceSwitch}
          </div>
        </SocketContext.Provider>
      </div>
    );
  }
}
